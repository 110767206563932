var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Frameyts",
    {
      key: _vm.proName,
      ref: "frameyts",
      attrs: {
        "menu-show": _vm.menuShow,
        "nav-show": _vm.navShow,
        "bread-list": _vm.breadList,
        "pro-name": _vm.proName,
        "pro-list": _vm.proList,
        "logo-url": _vm.logoUrl,
        title: _vm.title,
        "customer-props": _vm.customerProps,
        "it-report": true,
        "menu-list": _vm.activeNavList,
        "active-index": _vm.activeIndex,
        help: false,
        "more-function": _vm.moreFunction,
        "user-more": _vm.userMore,
        mode: _vm.mode,
        "user-infor": _vm.userInfor
      },
      on: {
        moreActiveCallBack: _vm.moreActiveCallBack,
        helpCallBack: _vm.helpCallBack,
        breadCallBack: _vm.breadCallBack,
        proCallBack: _vm.proCallBack,
        menuCallBack: _vm.menuCallBack,
        itReportCallBack: _vm.itReportCallBack,
        userMoreCallBack: _vm.userMoreCallBack,
        dropDownProShowCallBack: _vm.dropDownProShowCallBack
      }
    },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }